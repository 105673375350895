import React from "react";

export default function Header() {
  return (
    <div className="ekit-template-content-markup ekit-template-content-header ekit-template-content-theme-support">
      <div
        data-elementor-type="wp-post"
        className="elementor elementor-39"
        data-elementor-post-type="elementskit_template"
      >
        <div
          className="elementor-element elementor-element-db47a20 e-flex e-con-boxed e-con e-parent"
          data-id="db47a20"
          data-element_type="container"
          data-settings="{ background_background : classic , background_motion_fx_motion_fx_scrolling : yes , background_motion_fx_opacity_effect : yes , background_motion_fx_opacity_range :{ unit : % , size :  , sizes :{ start :0, end :3}}, background_motion_fx_blur_effect : yes , background_motion_fx_blur_level :{ unit : px , size :15, sizes :[]}, background_motion_fx_blur_range :{ unit : % , size :  , sizes :{ start :0, end :3}}, background_motion_fx_range : page , sticky : top , content_width : boxed , background_motion_fx_opacity_direction : out-in , background_motion_fx_opacity_level :{ unit : px , size :10, sizes :[]}, background_motion_fx_blur_direction : out-in , background_motion_fx_devices :[ desktop , tablet , mobile ], sticky_on :[ desktop , tablet , mobile ], sticky_offset :0, sticky_effects_offset :0}"
          data-core-v316-plus="true"
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-907b69f e-con-full e-flex e-con e-child"
              data-id="907b69f"
              data-element_type="container"
              data-settings="{ content_width : full }"
            >
              <div
                className="elementor-element elementor-element-225265f elementor-widget elementor-widget-image"
                data-id="225265f"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <a href="/">
                    <img
                      fetchpriority="high"
                      width={1184}
                      height={318}
                      src="/assets/images/logo-light.png"
                      className="attachment-full size-full wp-image-83"
                      alt=""
                    />{" "}
                  </a>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-5ffa37d elementor-widget elementor-widget-ekit-nav-menu"
                data-id="5ffa37d"
                data-element_type="widget"
                data-widget_type="ekit-nav-menu.default"
              >
                <div className="elementor-widget-container">
                  <div
                    className="ekit-wid-con ekit_menu_responsive_tablet"
                    data-hamburger-icon="icon icon-menu-7"
                    data-hamburger-icon-type="icon"
                    data-responsive-breakpoint={1024}
                  >
                    <button
                      className="elementskit-menu-hamburger elementskit-menu-toggler"
                      type="button"
                      aria-label="hamburger-icon"
                    >
                      <i
                        aria-hidden="true"
                        className="ekit-menu-icon icon icon-menu-7"
                      />{" "}
                    </button>
                    <div
                      id="ekit-megamenu-menu-header"
                      className="elementskit-menu-container elementskit-menu-offcanvas-elements elementskit-navbar-nav-default ekit-nav-menu-one-page- ekit-nav-dropdown-hover"
                    >
                      <ul
                        id="menu-menu-header"
                        className="elementskit-navbar-nav elementskit-menu-po-left submenu-click-on-icon"
                      >
                        <li
                          id="menu-item-65"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-65 nav-item elementskit-mobile-builder-content"
                          data-vertical-menu="750px"
                        >
                          <a href="#home" className="ekit-menu-nav-link active">
                            Home
                          </a>
                        </li>
                        <li
                          id="menu-item-66"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-66 nav-item elementskit-mobile-builder-content"
                          data-vertical-menu="750px"
                        >
                          <a href="#about-us" className="ekit-menu-nav-link">
                            About Us
                          </a>
                        </li>
                        <li
                          id="menu-item-79"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-79 nav-item elementskit-mobile-builder-content"
                          data-vertical-menu="750px"
                        >
                          <a href="#features" className="ekit-menu-nav-link">
                            Our Features
                          </a>
                        </li>
                        <li
                          id="menu-item-4171"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4171 nav-item elementskit-mobile-builder-content"
                          data-vertical-menu="750px"
                        >
                          <a href="#contact-us" className="ekit-menu-nav-link">
                            Contact Us
                          </a>
                        </li>
                      </ul>
                      <div className="elementskit-nav-identity-panel">
                        <div className="elementskit-site-title">
                          <a
                            className="elementskit-nav-logo"
                            href="#"
                            target="_self"
                            rel
                          >
                            <img
                              width={1184}
                              height={318}
                              src="/assets/images/Cryptopher-Logo-Light.png"
                              className="attachment-full size-full"
                              alt=""
                              decoding="async"
                            />
                          </a>
                        </div>
                        <button
                          className="elementskit-menu-close elementskit-menu-toggler"
                          type="button"
                        >
                          X
                        </button>
                      </div>
                    </div>
                    <div className="elementskit-menu-overlay elementskit-menu-offcanvas-elements elementskit-menu-toggler ekit-nav-menu--overlay" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-216ee8e e-con-full elementor-hidden-tablet elementor-hidden-mobile e-flex e-con e-child"
              data-id="216ee8e"
              data-element_type="container"
              data-settings="{ content_width : full }"
            >
              <div
                className="elementor-element elementor-element-92b5e76 elementor-align-right elementor-widget elementor-widget-button"
                data-id="92b5e76"
                data-element_type="widget"
                data-widget_type="button.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a
                      className="elementor-button elementor-button-link elementor-size-sm"
                      target="_blank"
                      href="https://brew.riverbays.com"
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-text">Login</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
