import React, { useState } from "react";
import CountUp from "react-countup";

const nodes = [
  { name: "Pro", price: 5000, royalty: 5 },
  { name: "Academy", price: 10000, royalty: 7 },
  { name: "College", price: 100000, royalty: 10 },
  { name: "University", price: 1000000, royalty: 15 },
];

const packages = {
  5000: { name: "Export", price: 100 },
  10000: { name: "Master", price: 200 },
  100000: { name: "Champion", price: 350 },
  1000000: { name: "Legend", price: 500 },
};

export default function Calculator() {
  const [selectedNode, setSelectedNode] = useState("");
  const [numberOfCourseSale, setNumberOfCourseSale] = useState(0);
  const [calcVal, setCalcVal] = useState({
    earning: 0,
    timeToMaxPatout: 0,
  });
  const [error, setError] = useState(null);

  const onBusinessVolChange = (e) => {
    let royalty = 0;
    if (selectedNode) {
      setError(null);
      let filtederdData = nodes.filter((node) => node.price == selectedNode);
      if (filtederdData.length > 0) {
        royalty = filtederdData[0].royalty;
      }
      let tempEarning =
        (Number(selectedNode) * Number(e.target.value) * royalty) / 100;
      let temp2 = tempEarning / ((Number(selectedNode) * 10) / 100);

      setCalcVal((prev) => ({
        ...prev,
        earning: tempEarning,
        timeToMaxPatout: Math.round(temp2),
      }));
      setNumberOfCourseSale(e.target.value);
    } else {
      setError("Please Select a Node to continue ...");
    }
  };

  return (
    <div
      className="elementor-element elementor-element-31c9aab1 e-flex e-con-boxed e-con e-parent"
      data-id="31c9aab1"
      data-element_type="container"
      data-settings="{ background_background : classic , content_width : boxed }"
      data-core-v316-plus="true"
      id="about-us"
    >
      <div className="e-con-inner">
        <div
          className="elementor-element elementor-element-7c2590d e-flex e-con-boxed e-con e-child"
          data-id="7c2590d"
          data-element_type="container"
          data-settings="{ content_width : boxed }"
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-9641c42 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
              data-id="9641c42"
              data-element_type="widget"
              data-widget_type="icon-list.default"
            >
              <div className="elementor-widget-container">
                <ul className="elementor-icon-list-items">
                  <li className="elementor-icon-list-item">
                    <span className="elementor-icon-list-icon">
                      <svg
                        aria-hidden="true"
                        className="e-font-icon-svg e-fab-bitcoin"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zm-141.651-35.33c4.937-32.999-20.191-50.739-54.55-62.573l11.146-44.702-27.213-6.781-10.851 43.524c-7.154-1.783-14.502-3.464-21.803-5.13l10.929-43.81-27.198-6.781-11.153 44.686c-5.922-1.349-11.735-2.682-17.377-4.084l.031-.14-37.53-9.37-7.239 29.062s20.191 4.627 19.765 4.913c11.022 2.751 13.014 10.044 12.68 15.825l-12.696 50.925c.76.194 1.744.473 2.829.907-.907-.225-1.876-.473-2.876-.713l-17.796 71.338c-1.349 3.348-4.767 8.37-12.471 6.464.271.395-19.78-4.937-19.78-4.937l-13.51 31.147 35.414 8.827c6.588 1.651 13.045 3.379 19.4 5.006l-11.262 45.213 27.182 6.781 11.153-44.733a1038.209 1038.209 0 0 0 21.687 5.627l-11.115 44.523 27.213 6.781 11.262-45.128c46.404 8.781 81.299 5.239 95.986-36.727 11.836-33.79-.589-53.281-25.004-65.991 17.78-4.098 31.174-15.792 34.747-39.949zm-62.177 87.179c-8.41 33.79-65.308 15.523-83.755 10.943l14.944-59.899c18.446 4.603 77.6 13.717 68.811 48.956zm8.417-87.667c-7.673 30.736-55.031 15.12-70.393 11.292l13.548-54.327c15.363 3.828 64.836 10.973 56.845 43.035z" />
                      </svg>
                    </span>
                    <span className="elementor-icon-list-text">About Us</span>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-c9e6344 elementor-widget__width-initial elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading"
              data-id="c9e6344"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  Dominate Affiliate Marketing
                </h2>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-3025f87 elementor-widget__width-initial elementor-widget-tablet__width-inherit elementor-widget elementor-widget-text-editor"
              data-id="3025f87"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container">
                <p>
                  Leaders in Crypto and Blockchain Development, we innovate
                  blockchain solutions, shaping the digital future with
                  excellence and forward-thinking strategies.
                </p>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-5c5e462 elementor-align-left elementor-icon-list--layout-inline elementor-tablet-align-center elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
              data-id="5c5e462"
              data-element_type="widget"
              data-widget_type="icon-list.default"
            >
              <div className="elementor-widget-container">
                <ul className="elementor-icon-list-items elementor-inline-items">
                  <li className="elementor-icon-list-item elementor-inline-item">
                    <span className="elementor-icon-list-icon">
                      <svg
                        aria-hidden="true"
                        className="e-font-icon-svg e-fas-check-circle"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" />
                      </svg>
                    </span>
                    <span className="elementor-icon-list-text">
                      Partner-Focused Strategy
                    </span>
                  </li>
                  <li className="elementor-icon-list-item elementor-inline-item">
                    <span className="elementor-icon-list-icon">
                      <svg
                        aria-hidden="true"
                        className="e-font-icon-svg e-fas-check-circle"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" />
                      </svg>
                    </span>
                    <span className="elementor-icon-list-text">
                      Trusted Blockchain Expertise
                    </span>
                  </li>
                  <li className="elementor-icon-list-item elementor-inline-item">
                    <span className="elementor-icon-list-icon">
                      <svg
                        aria-hidden="true"
                        className="e-font-icon-svg e-fas-check-circle"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" />
                      </svg>
                    </span>
                    <span className="elementor-icon-list-text">
                      Comprehensive Advisory Services
                    </span>
                  </li>
                  <li className="elementor-icon-list-item elementor-inline-item">
                    <span className="elementor-icon-list-icon">
                      <svg
                        aria-hidden="true"
                        className="e-font-icon-svg e-fas-check-circle"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" />
                      </svg>
                    </span>
                    <span className="elementor-icon-list-text">
                      Dedicated Support Team
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className="elementor-element elementor-element-d1f4aa7 e-flex e-con-boxed e-con e-child"
          data-id="d1f4aa7"
          data-element_type="container"
          data-settings="{ content_width : boxed }"
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-6e6ff4a e-flex e-con-boxed e-con e-child"
              data-id="6e6ff4a"
              data-element_type="container"
              data-settings="{ background_background : classic , content_width : boxed }"
            >
              <div className="e-con-inner">
                <div
                  className="elementor-element elementor-element-87feef5 counter-align_center elementor-widget elementor-widget-counter"
                  data-id="87feef5"
                  data-element_type="widget"
                  data-widget_type="counter.default"
                >
                  <div className="elementor-widget-container">
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          "/*! elementor - v3.19.0 - 07-02-2024 */\n                                    .elementor-counter .elementor-counter-number-wrapper{display:flex;font-size:69px;font-weight:600;line-height:1}.elementor-counter .elementor-counter-number-prefix,.elementor-counter .elementor-counter-number-suffix{flex-grow:1;white-space:pre-wrap}.elementor-counter .elementor-counter-number-prefix{text-align:right}.elementor-counter .elementor-counter-number-suffix{text-align:left}.elementor-counter .elementor-counter-title{text-align:center;font-size:19px;font-weight:400;line-height:2.5}\n                                 ",
                      }}
                    />
                    <div className="elementor-counter">
                      <div className="elementor-counter-number-wrapper">
                        <span className="elementor-counter-number-prefix" />
                        <span className="elementor-counter-number">
                          <CountUp duration={2} end={125} />
                        </span>
                        <span className="elementor-counter-number-suffix">
                          +
                        </span>
                      </div>
                      <div className="elementor-counter-title">
                        Project Finished
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-68cdcb3 e-flex e-con-boxed e-con e-child"
              data-id="68cdcb3"
              data-element_type="container"
              data-settings="{ background_background : classic , content_width : boxed }"
            >
              <div className="e-con-inner">
                <div
                  className="elementor-element elementor-element-5f66f16 counter-align_center elementor-widget elementor-widget-counter"
                  data-id="5f66f16"
                  data-element_type="widget"
                  data-widget_type="counter.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-counter">
                      <div className="elementor-counter-number-wrapper">
                        <span className="elementor-counter-number-prefix" />
                        {/* <span className="elementor-counter-number" data-duration={2000} data-to-value={1978} data-from-value={0} data-delimiter=",">0</span> */}
                        <span className="elementor-counter-number">
                          <CountUp duration={5} end={1978} />
                        </span>
                        <span className="elementor-counter-number-suffix">
                          B
                        </span>
                      </div>
                      <div className="elementor-counter-title">
                        Transactions
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-cf6c58a e-flex e-con-boxed e-con e-child"
              data-id="cf6c58a"
              data-element_type="container"
              data-settings="{ background_background : classic , content_width : boxed }"
            >
              <div className="e-con-inner">
                <div
                  className="elementor-element elementor-element-73df7d7 counter-align_center elementor-widget elementor-widget-counter"
                  data-id="73df7d7"
                  data-element_type="widget"
                  data-widget_type="counter.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-counter">
                      <div className="elementor-counter-number-wrapper">
                        <span className="elementor-counter-number-prefix" />
                        {/* <span className="elementor-counter-number" data-duration={2000} data-to-value={25} data-from-value={0} data-delimiter=",">0</span> */}
                        <span className="elementor-counter-number">
                          <CountUp duration={3} end={25} />
                        </span>
                        <span className="elementor-counter-number-suffix">
                          +
                        </span>
                      </div>
                      <div className="elementor-counter-title">
                        Year of Experience
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-2bfcabf e-flex e-con-boxed e-con e-child"
              data-id="2bfcabf"
              data-element_type="container"
              data-settings="{ background_background : classic , content_width : boxed }"
            >
              <div className="e-con-inner">
                <div
                  className="elementor-element elementor-element-4f29cee counter-align_center elementor-widget elementor-widget-counter"
                  data-id="4f29cee"
                  data-element_type="widget"
                  data-widget_type="counter.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-counter">
                      <div className="elementor-counter-number-wrapper">
                        <span className="elementor-counter-number-prefix" />
                        {/* <span className="elementor-counter-number" data-duration={2000} data-to-value={380} data-from-value={0} data-delimiter=",">0</span> */}
                        <span className="elementor-counter-number">
                          <CountUp duration={4} end={380} />
                        </span>
                        <span className="elementor-counter-number-suffix">
                          K
                        </span>
                      </div>
                      <div className="elementor-counter-title">
                        Clients Worldwide
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="e-con-inner mt-5">
          <div
            className="elementor-element elementor-element-7c2590d e-flex e-con-boxed e-con e-child"
            data-id="7c2590d"
            data-element_type="container"
            data-settings="{ content_width : boxed }"
          >
            <div className="e-con-inner">
              <div
                className="elementor-element elementor-element-9641c42 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                data-id="9641c42"
                data-element_type="widget"
                data-widget_type="icon-list.default"
              >
                <div className="elementor-widget-container">
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <svg
                          aria-hidden="true"
                          className="e-font-icon-svg e-fab-bitcoin"
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zm-141.651-35.33c4.937-32.999-20.191-50.739-54.55-62.573l11.146-44.702-27.213-6.781-10.851 43.524c-7.154-1.783-14.502-3.464-21.803-5.13l10.929-43.81-27.198-6.781-11.153 44.686c-5.922-1.349-11.735-2.682-17.377-4.084l.031-.14-37.53-9.37-7.239 29.062s20.191 4.627 19.765 4.913c11.022 2.751 13.014 10.044 12.68 15.825l-12.696 50.925c.76.194 1.744.473 2.829.907-.907-.225-1.876-.473-2.876-.713l-17.796 71.338c-1.349 3.348-4.767 8.37-12.471 6.464.271.395-19.78-4.937-19.78-4.937l-13.51 31.147 35.414 8.827c6.588 1.651 13.045 3.379 19.4 5.006l-11.262 45.213 27.182 6.781 11.153-44.733a1038.209 1038.209 0 0 0 21.687 5.627l-11.115 44.523 27.213 6.781 11.262-45.128c46.404 8.781 81.299 5.239 95.986-36.727 11.836-33.79-.589-53.281-25.004-65.991 17.78-4.098 31.174-15.792 34.747-39.949zm-62.177 87.179c-8.41 33.79-65.308 15.523-83.755 10.943l14.944-59.899c18.446 4.603 77.6 13.717 68.811 48.956zm8.417-87.667c-7.673 30.736-55.031 15.12-70.393 11.292l13.548-54.327c15.363 3.828 64.836 10.973 56.845 43.035z" />
                        </svg>
                      </span>
                      <span className="elementor-icon-list-text">
                        Calculator
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-c9e6344 elementor-widget__width-initial elementor-widget-tablet__width-inherit elementor-widget elementor-widget-heading"
                data-id="c9e6344"
                data-element_type="widget"
                data-widget_type="heading.default"
              >
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    Know your earnings using our calculator
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="elementor-element elementor-element-c18a5ce e-flex e-con-boxed e-con e-child">
          <div className="m-box">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-4">
                  <label className="mb-2">Choose Your Node</label>
                  <select
                    className="w-100 m-select"
                    value={selectedNode}
                    onChange={(e) => setSelectedNode(e.target.value)}
                  >
                    <option value="">Select Node</option>
                    {nodes?.map((ele) => (
                      <option value={ele.price}>
                        {ele.name} - {ele.price}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-4">
                  <label className="mb-2">Your Package</label>
                  <select
                    disabled
                    className="w-100 m-select"
                    value={selectedNode ? packages[selectedNode].name : ""}
                  >
                    <option value="">Select Node</option>
                    {Object.values(packages).map((ele) => (
                      <option value={ele.name}>
                        {ele.name} - {ele.price}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-4">
                  <label className="mb-0">
                    Number Of Course Sale - {numberOfCourseSale}
                  </label>
                  <input
                    type="range"
                    className="w-100"
                    value={numberOfCourseSale}
                    onChange={onBusinessVolChange}
                    min={0}
                    max={100}
                  />
                  <label className="mb-0 text-danger">{error}</label>
                </div>

                <div className="mb-4">
                  <div className="row">
                    {/* <div className='col-md-6 mb-4'>
                      <div className="elementor-element elementor-element-6e6ff4a e-flex e-con-boxed e-con e-child" data-id="6e6ff4a" data-element_type="container" data-settings="{ background_background : classic , content_width : boxed }">
                        <div className="e-con-inner">
                          <div className="elementor-element elementor-element-87feef5 counter-align_center elementor-widget elementor-widget-counter" data-id="87feef5" data-element_type="widget" data-widget_type="counter.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-counter">
                                <div className="elementor-counter-number-wrapper">
                                  <span className="elementor-counter-number-prefix" />
                                  <span className="elementor-counter-number">
                                    7500
                                  </span>
                                  <span className="elementor-counter-number-suffix"></span>
                                </div>
                                <div className="elementor-counter-title">Rank</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 mb-4'>
                      <div className="elementor-element elementor-element-6e6ff4a e-flex e-con-boxed e-con e-child" data-id="6e6ff4a" data-element_type="container" data-settings="{ background_background : classic , content_width : boxed }">
                        <div className="e-con-inner">
                          <div className="elementor-element elementor-element-87feef5 counter-align_center elementor-widget elementor-widget-counter" data-id="87feef5" data-element_type="widget" data-widget_type="counter.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-counter">
                                <div className="elementor-counter-number-wrapper">
                                  <span className="elementor-counter-number-prefix" />
                                  <span className="elementor-counter-number">
                                    7500
                                  </span>
                                  <span className="elementor-counter-number-suffix"></span>
                                </div>
                                <div className="elementor-counter-title">Level</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-6 mb-4">
                      <div
                        className="elementor-element elementor-element-6e6ff4a e-flex e-con-boxed e-con e-child"
                        data-id="6e6ff4a"
                        data-element_type="container"
                        data-settings="{ background_background : classic , content_width : boxed }"
                      >
                        <div className="e-con-inner">
                          <div
                            className="elementor-element elementor-element-87feef5 counter-align_center elementor-widget elementor-widget-counter"
                            data-id="87feef5"
                            data-element_type="widget"
                            data-widget_type="counter.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="elementor-counter">
                                <div className="elementor-counter-number-wrapper">
                                  <span className="elementor-counter-number-prefix" />
                                  <span className="elementor-counter-number">
                                    {calcVal?.earning}
                                  </span>
                                  <span className="elementor-counter-number-suffix">
                                    {" "}
                                    $
                                  </span>
                                </div>
                                <div className="elementor-counter-title">
                                  Earning
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div
                        className="elementor-element elementor-element-6e6ff4a e-flex e-con-boxed e-con e-child"
                        data-id="6e6ff4a"
                        data-element_type="container"
                        data-settings="{ background_background : classic , content_width : boxed }"
                      >
                        <div className="e-con-inner">
                          <div
                            className="elementor-element elementor-element-87feef5 counter-align_center elementor-widget elementor-widget-counter"
                            data-id="87feef5"
                            data-element_type="widget"
                            data-widget_type="counter.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="elementor-counter">
                                <div className="elementor-counter-number-wrapper">
                                  <span className="elementor-counter-number-prefix" />
                                  <span className="elementor-counter-number">
                                    {calcVal?.timeToMaxPatout}
                                  </span>
                                  <span className="elementor-counter-number-suffix">
                                    {" "}
                                    Days
                                  </span>
                                </div>
                                <div className="elementor-counter-title">
                                  Time To Max Payout
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 center-center">
                {/* Right SIde */}
                <img
                  src="/assets/images/Calculator-v2-549AXBK.png"
                  className="img-fluid w-50"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
